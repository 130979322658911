const useSession = () => {
  const sessionCookie = useCookie("ssid");
  const { fetchBranch, fetchBranchBySlug, selectedBranch } = useBranches();
  const { fetchCustomer } = useUser();
  const { fetchCart } = useCart();
  const route = useRoute();

  const getCustomer = async () => {
    if (selectedBranch.value) {
      await fetchCustomer();
    }
  };

  const fetchBranchAndSelectRoute = async () => {
    let fetchedBranch;

    if (
      route.query.branch &&
      route.path !== "/cart" &&
      route.path !== "/cart/" &&
      route.path !== "/checkout-login" &&
      route.path !== "/checkout-login/"
    ) {
      const slug = route.query.branch as string;
      fetchedBranch = await fetchBranchBySlug(slug);
    }

    if (
      fetchedBranch &&
      fetchedBranch[0].id !== selectedBranch.value?.id &&
      selectedBranch.value?.status === "open" &&
      fetchedBranch[0].type !== "home"
    ) {
      // TODO: [2024-11-30]:: Implement modal to select branch for cart fetch when doing cart page
    }
  };

  const connect = async () => {
    if (process.server) {
      const getSession = async () => {
        if (!sessionCookie.value) {
          const { data, error } = await useAsyncData("createSession", () => useSdk().mkmAPI.createSession({}));

          useHandleError(error.value);

          const setSessionCookie = useCookie("ssid", {
            expires: new Date(data.value.expires),
            path: "/",
            httpOnly: true,
            secure: true,
          });

          setSessionCookie.value = data.value.ssid;
        }
      };

      try {
        const begin = Date.now();

        await getSession();

        const message = `create session and get categories - ${Date.now() - begin} ms`;
        // eslint-disable-next-line no-console
        console.info(`[SSR] ${message}`);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const mount = async () => {
    await Promise.all([fetchBranch()]);
    await Promise.all([getCustomer(), fetchCart(), fetchBranchAndSelectRoute()]);
  };

  return {
    connect,
    mount,
  };
};

export default useSession;
